import './App.css';
import { useLocation, useRoutes } from 'react-router-dom';
import { routes } from './Routes/Routes';
import { Suspense, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import useSocket from './Hooks/useSocket';
import Header from './Modules/Dashboard/User/Header/Header';
import NavLoader from './Components/NavLoader/NavLoader';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import UserService from './Services/user.service';
import { uniqueId } from 'lodash';

export let socket;

const sessionId = uniqueId();

const detectDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (/mobile|android|kindle|silk|midp|phone|(windows .+arm|touch)/.test(userAgent)) {
    return 'Mobile';
  }
  return 'Desktop';
};

function App() {
  const socketUrl = 'https://test.thaliva.com:4443';
  [socket] = useSocket(socketUrl, { transports: ['websocket'] });
  const location = useLocation();

  const actions = useSelector(state => state?.user?.actions || []);
  const toast = useRef(null);

  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        return response.data.country_name;
      } catch (error) {
        console.error('Could not fetch user country:', error);
        return 'Unknown';
      }
    };

    const storeSessionData = async () => {
      const country = await fetchUserCountry();
      const device = detectDevice();

      try {
        await UserService.sessionInfo({
          sessionId,
          country,
          device,
        });
      } catch (error) {

      }
    };

    const endSession = async () => {
      try {
        await UserService.endSession({
          sessionId,
        });
      } catch (error) {
        console.error('Could not end session:', error);
      }
    };

    
    storeSessionData();
    return () => endSession();
  }, []);

  const showSuccess = (value) => {
    toast.current.show({ severity: 'success', summary: value.detail, detail: value.message });
  }

  return (
    <>
      <Toast ref={toast} />
      {
        location?.pathname?.includes('/clubhouse') && (
          <Header />
        )
      }

      <Suspense fallback={<NavLoader />}>
      <ToastContainer />
        {
          useRoutes(routes({ actions, showSuccess }))
        }
      </Suspense>
    </>
  )
}

export default App;
